import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import Person from '../components/person'
import SEO from '../components/seo'

export const query = graphql`
  query PersonTemplate($id: String!) {
    person: sanityPerson(id: { eq: $id }) {
      name
      slug {
        current
      }
      position
      linkedin
      twitter
      image {
        asset {
          _id
        }
      }
      bioNorm
    }
  }
`

const PersonTemplate = props => {
  const { data, errors } = props
  const person = data && data.person
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {person && <SEO title={person.name || 'Untitled'} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {person && <Person {...person} />}
    </Layout>
  )
}

export default PersonTemplate
