import React from 'react'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const paragraphClass = 'dib relative w-100 measure-wide gray fw4'
const headingClass = 'dib relative w-100 measure f2 fw5 mt0 mb3'
const linkClass = 'dib relative v-bottom w-auto link dim gray ma0 f6 fw4'

function Person({ name, position, linkedin, twitter, image, bioNorm }) {
  const Image = ({ image, alt }) => (
    <img
      className="dib relative w-100 w-40-l fit mr0 mr4-l"
      src={imageUrlFor(buildImageObj(image))}
      alt={alt}
    />
  )

  const Position = ({ position }) => <p className="dib relative w-100 dark-gray mv0">{position}</p>

  const LinkedIn = ({ name, href }) => (
    <a
      className={linkClass}
      onClick={() => {
        this.logClick(name, 'LinkedIn')
      }}
      target="_blank"
      href={href}
      rel="noopener noreferrer"
    >
      <img
        className="dib relative h1 w1 v-mid mr2"
        src="/icons/social/linkedin.svg"
        alt="LinkedIn"
      />
      <span className="dib relative v-mid">LinkedIn</span>
    </a>
  )

  const Twitter = ({ name, href }) => (
    <a
      className={linkClass}
      onClick={() => {
        this.logClick(name, 'Twitter')
      }}
      target="_blank"
      href={href}
      rel="noopener noreferrer"
    >
      <img className="dib relative h1 w1 v-mid mr2" src="/icons/social/twitter.svg" alt="Twitter" />
      <span className="dib relative v-mid">Twitter</span>
    </a>
  )

  const Bio = ({ bio }) => <p className={paragraphClass}>{bioNorm}</p>

  return (
    <article>
      <section className="dib relative w-100 lh-copy black f5">
        <div className="db center mw8 ph4 pv6 pv7-l">
          <div className="dib flex-l justify-between items-center">
            {image ? <Image image={image} alt={name} /> : null}
            <div className="dib relative v-vid w-100 w-60-l">
              <h1 className={headingClass}>{name}</h1>
              {position ? <Position position={position} /> : null}
              {bioNorm ? <Bio bio={bioNorm} /> : null}
              {linkedin ? (
                <span className="dib relative w-100">
                  <LinkedIn name={name} href={linkedin} />
                </span>
              ) : null}
              {twitter ? (
                <span className="dib relative w-100">
                  <Twitter name={name} href={twitter} />
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </article>
  )
}

export default Person
